@import '../../css/style.scss';

#menu-open{
    display: flex;
}

#menu-close{
    
    @include responsive(s){
        display: none;
    }
}

.temas__menu{
    width: 14vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $gray-2;
    position: fixed;
    top: 0;
    left: 0;

    @include responsive(s){
        min-height: 100vh;
        min-width: 100vw;
        background-color: $white-bg;
        z-index: 10;
        display: flex;
        justify-content: space-evenly;
    }

    @include responsive(xs){
        width: 64vw;
    }

    .menu-item{

        &:hover{
            font-weight: bold;
        }
    }
        
    .menu-dkt{
        @include responsive(s){
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .PrimeiraSeção {
            position: relative;

            &::after{
                content: '';
                left: 0;
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: -2.3rem;
                background-color: $gray-2;

                @include responsive(l){
                    bottom: -1.6rem;
                }

                @include responsive(xs){
                    bottom: -2rem;
                }

            }
        }

        .SegundaSeção {
            margin-top: 63px;
            position: relative;

            @include responsive(l){
                margin-top: 45px;
            }

            &::after{
                content: '';
                left: 0;
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: -2.4rem;
                background-color: $gray-2;

                @include responsive(l){
                    bottom: -2rem;
                }
            }
        }
    }

    .menu-dkt > img{
        width: 95px;
        height: fit-content;
        margin: 25px auto 25px auto;
        cursor: pointer;

        @include responsive(m){
            width: 90px;
        }

        @include responsive(s){
            display: none;
        }
    }

    .box-menu-temas{
        display: flex;
        margin: 10px 0 5px 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;

        .menu-item, .menu-1, .menu-2, .menu-3, .menu-4{
            font-size: 19px;

            @include responsive(l){
                font-size: 13px;
            }

            @include responsive(s){
                font-size: 18px;
            }
        }
    

        @include responsive(l){
            margin-left: 5px;
        }

        img{
            width: 30px;
            height: 30px;
            margin-right: 5px;

            @include responsive(l){
                margin-right: 1px;
            }
        }

        
    }  

    .check-privacy{
        color: $gray-5;
        font-size: 14px;
        margin: 5rem auto 0px auto;
        transition: .2s ease-in-out;

        @include responsive(s){
            margin-top: 0px;
            font-size: 18px;
        }

        &:hover{
            color: darken($gray-5, 20%);
            scale: .9;
        }
    }

    .btn-logout{
        font-size: 14px;
        padding: 5px 15px;
        color: $gray-5;
        cursor: pointer;
        position: absolute;
        right: calc(50% - 29px);
        bottom: 5vh;
        transition: .2s ease-in-out;

        &:hover {
            color: darken($gray-5, 20%);
            scale: .9;
        }
    }

    .bold{
        font-weight: bold;
    }
}

.icon{
    position: fixed;
    left: 2%;
    top: 5%;
    width: fit-content;
    z-index: 11;
    height: 44px;
    display: none;

    @include responsive(s){
        display: block;
    }

    .menu-bx{
        height: 100%;
        width: 40px;
        position: relative;

        &::after{
            content: '';
            position: absolute;
            width: 90%;
            height: 10%;
            background-color: black;
            border-radius: 14px;
            right: 0;
            top: 30%;
            transition: 400ms ease;
        }

        &::before{
            content: '';
            position: absolute;
            width: 90%;
            height: 10%;
            background-color: black;
            border-radius: 14px;
            right: 0;
            top: 60%;
            transition: 400ms ease;
        }

    }
}

.iconActive .menu-bx{
    &::before{
        transform: rotate(232deg) translate(10px, 7px);
    }

    &::after{
        transform: rotate(-230deg) translate(1px, 0px);
    }
}