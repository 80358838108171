@import '../../css/style.scss';

.container-temas{
    margin-bottom: 200px;
    width: 100vw;

    @include responsive(s){
        display: flex;
        align-items: center;
        justify-content: center
    }

    .container-temas-main{
        overflow-x: hidden;
        width: 85vw;
        height: fit-content;
        transform: translateX(14vw);
        padding-left: 4vw;
        margin-top: 190px;
        position: relative;
        z-index: 9;

        @include responsive(l){
            margin-top: 150px;
        }

        @include responsive(s){
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            transform: translateX(0px);
        }
    }
}