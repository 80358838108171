@import '../../css/style.scss';

.container-pop-up{
    width: 400px;
    height: 250px;
    padding: 10px;
    color: black;
    background-color: $white-bg;
    box-shadow: 0px 0px 13px -3px rgba(74,78,105,1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: calc(50vh - 100px);
    left: calc(50vw - 150px);
    z-index: 8;

    @include responsive(xs){
        width: 350px;
        left: calc(50vw - 175px);
    }

    h3{
        color: #4a4e69;
        font-size: 20px;
        text-align: center;
        margin-top: 10px;

        @include responsive(l){
            font-size: 16px;
        }
    }

    .btn-pop{
        display: inline-block;
        background-color: #5B97DB;
        color: $white-bg;
        border-radius: 15px;
        font-size: 18px;
        padding: 10px 65px 10px 65px;
        transition: all 400ms ease;
        
        @include responsive(l){
            padding: 10px 58px 10px 58px;
        }

        &:hover{
            transform: translateY(-5px);
        }
    }

    .icon-alert{
        position: absolute;
        top: -10px;
        right: -1px;
        fill: #5B97DB;
        width: 32px;
        height: fit-content;
    }
}