@import '../../css/style.scss';

.container-cadastro{
    display: flex;
    width: 100%;

    .container-bg {
        display: flex;
        width: 55%;
        min-height: 100vh;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../../assets/login-texto.jpg');

        @include responsive(s) {
            width: auto;
            background-position: center 54%;
            min-height: 25vh;
        }
    }

    @include responsive(s) {
        flex-direction: column;
    }

    .required{
        display: none;
        color: red;

        @include responsive(xs){
            transform: translateY(10px);
        }
    }

    
        
    .img-wallpaper {
        width: 55%;
        height: 100%;

        @include responsive(l){
            object-position: right;
        }
    
        @include responsive(m){
            object-position: right;
        }
    
        @include responsive(s){
            display: none;
        }
    }
    

    .login__box-left {
        display: flex;
        width: 45%;
        height: fit-content;
        min-height: 100vh;
        flex-direction: column;
        margin-left: auto;

        @include responsive(s){
            width: 100%;
        }

        .cadastro__form{
            display: flex;
            flex-direction: column;
    
            .box-input{
                margin-top: 20px;
                margin-right: auto;

                @include responsive(xs){
                    margin-top: 40px;
                }
            }
            
            .btn-back{
                display: inline-block;
                color: #BFBFBF;
                opacity: 50%;
                padding: 0.3rem;
                margin: 0.6rem auto 0px auto;
            }
        }

        .container-form {  
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: column;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;
            margin-right: auto;

            .title {
                padding: 0px;
                width: fit-content;
    
                @include responsive(m){
                    font-size: 50px;
                };

                @include responsive(xs){
                    font-size: 35px;
                    margin-top: 35px;
                };
            }

            .logo-invisible{
                display: flex;
                width: 20px;
                height: 20px;
                cursor: pointer;
                position: relative;
                bottom: 4.5vh;
                margin-left: auto;
            }
    
            .logo-invisible2{
                display: flex;
                width: 20px;
                height: 20px;
                cursor: pointer;
                position: relative;
                bottom: 4.5vh;
                margin-left: auto;
            }

            .btn{
                margin-top: 1.5rem;
                width: 100%;
            
                @include responsive(xs){
                    margin-top: 3rem;
                }
            }

            .logo-abrafati{
                margin: 1.6rem auto 20px auto;
                
            }
        }
    }
}