@import '../../css/style.scss';

.container-cadastro3{
    display: flex;
    width: 100%;

    .container-loading {
        background-color: #00000073;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .container-bg {
        display: flex;
        width: 55%;
        min-height: 100vh;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../../assets/login-texto.jpg');

        @include responsive(s) {
            width: auto;
            background-position: center 54%;
            min-height: 25vh;
        }
    }

    @include responsive(s) {
        flex-direction: column;
    }

    @include responsive(l){
        p{
            font-size: 16px;
        }
    }
    @include responsive(s){
        height: fit-content;
        justify-content: center;
        p{
            font-size: 22px;
        }
    }
    @include responsive(xs){
        margin-bottom: 30px;
        height: fit-content;

        p{
            font-size: 18px;
        }
    }

    .title-question3{
        color: black;
        opacity: 1;
    }

    .container-pop{
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 2;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.2);
    }

    .active-pop-email{
        width: 450px;
        height: 180px;
        position: relative;
        background-color: $white-bg;
        border-radius: 15px;
        box-shadow: 2px 2px 2px lighten($gray-place, 10%);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px;

        .pop-email-close{
            padding: 5px 15px;
            background-color: $blue-button;
            border-radius: 5px;
            color: white;
            position: absolute;
            top: 0px;
            right: 0;
            cursor: pointer;
        }
    }

    .active-pop-admin{
        width: 450px;
        height: 180px;
        position: relative;
        background-color: $white-bg;
        border-radius: 15px;
        box-shadow: 0px 2px 2px lighten($gray-place, 10%);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        padding: 5px;

        .pop-admin-close{
            border: none;
            font-size: 18px;
            padding: 10px 80px;
            background-color: $blue-button;
            border-radius: 15px;
            color: white;
            margin-top: 30px;
            margin-bottom: 3px;
            cursor: pointer;
            transition: 400ms ease;

            @include responsive(l){
                font-size: 17px;
            }

            &:hover{
                transform: translateY(-3px);
            }
        }
    }

    .cadastro__alert{
        position: fixed;
        width: 200px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white-bg;
        bottom: 40%;
        left: 70%;
        z-index: 2;
        box-shadow: 0px 2px 2px lighten($gray-place, 10%);
        border-radius: 15px;


        @include responsive(s){
            right: calc(50% - 60px);
        }

        // .alert__btn-disable{
        //     position: absolute;
        //     background-color: red;
        //     right: 0;
        //     padding: 3px 6px;
        //     top: -10px;
        //     color: $white-bg;
        //     text-align: center;
        // }
    }

    .login__box-left{

        form{

            @include responsive(l){
                width: 80%;
            }

            @include responsive(s){
                width: 60%;
            }
    
            @include responsive(xs){
                width: 90%;
            }
        }
    }

    p{
        font-size: 20px;
    }

    .title{
        margin: 75px 0 40px 0;
        width: 30vw;

        @include responsive(l){
            margin-top: 40px;
        }
        @include responsive(s){
            font-size: 45px;
        }
        @include responsive(xs){
            width: fit-content;
            font-size: 35px;
        }
    }

    .container-input-registration{
        overflow: hidden;
        margin-top: 10px;
        transition: all 1s ease;
        margin-bottom: 25px;
        font-size: 18px;
        position: relative;


        select{
            outline: none;
            border: none;
            width: 100%;
            background-color: white;
            border-left: 3px solid $blue-title;
            box-shadow: 0px 2px 2px #d9d9d9;
            color: $gray-place;
            padding: 7px;
            font-size: 16px;

            option{
                color: black;

                &:disabled {
                    color: $gray-place;
                }
            }
        }
    }

    input[type="number"]{
        width: 100%;
        color: $gray-place;
        padding: .5rem 0rem .4rem 0rem;
        margin-top: 5px;
        border: none;
        border-bottom: 3px solid $gray-2;
        outline-color: $blue-title;
        font-size: 16px;

        &::placeholder{
            font-size: 16px;
            color: $gray-place;
        }

        &:focus {
            outline: none;
        }
    }

    .box-check-direitos{
        width: 30vw;
        font-size: 14px;
        margin-top: 85px;
        cursor: pointer;
        display: flex;
        position: relative;

        @include responsive(l){
            margin-top: 15px;
        }
        @include responsive(s){
            width: fit-content;
            font-size: 20px;
        }

        input{
            margin-right: 5px;
            cursor: pointer;
            width: fit-content;
        }

        p{
            font-size: 1.5vh;
            a{
                color: $blue-title;  
                cursor: pointer;
                position: relative;
    
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $blue-title;
                    opacity: 0;
                }
            
                &:hover{
                    &::after{
                        opacity: 0.7;
                    }
                }
            }
        }

        .alert-termos{
            position: absolute;
            bottom: -1.7rem;
            color: red;
            font-size: 0.7rem;
            opacity: 1;
            display: none;
        }
    }

    .show-container{
        height: fit-content;
        overflow:auto;
    }

    .alert-erro{
        position: relative;
        color: red;
        opacity: 1;
        transform: translateY(20px);
        display: none;
        
        @include responsive(l){
            font-size: 18px;
        }
    }

    .alert-cadastro3{
        color: red;
        opacity: 1;
        margin-top: 5px;
        display: none;
               
        @include responsive(l){
            font-size: 14px;
        }
    }

    .btn{
        width: 100%;
        margin: 70px auto 25px auto;

        @include responsive(l){
            margin-top: 40px;
        }
    }

    .btn-back{
        color: $gray-place;
        all: unset;
        opacity: 50%;
        padding: .3rem;
        
        &:hover {
            opacity: 65%;
            border-bottom: 1px solid darken($gray-place, 20%);
            cursor: pointer;
        }

        @include responsive(l){
            font-size: 16px;
        }
    }
}