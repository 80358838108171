@import '../../css/style.scss';

.card-dowload{
    width: 322px;
    height: 351px;
    background-color: #F9F9F9;
    box-shadow: 0px 0px 20px #00000017;
    border-radius: 0px 25px 25px 25px;
    padding: 10px;
    text-align: center;
    margin: 10px;

    @include responsive(l){
        width: 220px;
        height: 278px;
    }

    img{
        width: 100%;
        height: fit-content;
    }

    h4{
        margin: 20px 0 30px 0;
        font-size: 20px;

        @include responsive(l){
            font-size: 14px;
        }
    }

    .btn-dowload{
        text-decoration: none;
        display: inline-block;
        width: 95%;
        padding: 15px;
        margin-top: 16px;
        font-size: 21px;
        background-color: $color-ch;
        color: $white-bg;
        border: none;
        border-radius: 15px;
        cursor: pointer;

        &:hover{
            cursor: pointer;
        }

        @include responsive(l){
            margin-top: 25px;
            font-size: 16px;
            padding: 6px;
        }
    }
}