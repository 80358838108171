@import '../../css/style.scss';

.box-apresentacao-empresa{
    height: 16vh;
    width: 86vw;
    transform: translateX(14vw);
    border-bottom: 1px solid $gray-2;
    display: flex;
    align-items: center;
    justify-content: right;
    position: fixed;
    background: #ffffff;
    top: 0;
    z-index: 21;

    @include responsive(s){
        width: 100%;
        background-color: $white-bg;
        transform: translateX(0px);
        z-index: 10;
    }

    .box-information{
        text-align: end;
        position: relative;

        &::after{
            content: '';
            right: -90px;
            top: 0;
            position: absolute;
            width: 4px;
            height: 105%;
            background-color: $blue-title
        }

        .box-info-user{
            margin-bottom: 7px;
        }
    }

    p{
        color: $gray-5;
        font-size: 24px;

        @include responsive(l){
            font-size: 16px;
        }
    }
    .apresentacao__name-empresa{
        font-size: 21px;
        color: $black;
        font-weight: 700;
        opacity: 0.78;
        margin-bottom: 5px;

        @include responsive(l){
            font-size: 15px;
        }
    }

    .email-user{

        @include responsive(xs){
            font-size: 13px;
        }
    }

    .picture-company{
        display: flex;
        width: 100px;
        width: 52px;
        height: 52px;
        margin: 0 10vw 0 15px;
        // aspect-ratio: 16/9;
        background-color: #fafafa;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .picture-input{
            all: unset;
            display: none;
        }

        .picture-img, .picture-img-example{
            max-width: 100%;
            background-color: $gray-2;
        }
    }

    .picture-box-image{
        position: relative;

        & > svg{
            width: 54px;
            fill: #fff;
            color: #fff;
            filter: drop-shadow(0px 1px 1px black);
        }

        &::after{
            content: attr(tooltip);
            position: absolute;
            bottom: -6%px;
            font-size: 14px;
            background-color: $blue-title;
            border-radius: 8px;
            color: #fff;
            font-weight: 400;
            font-family: "Roboto", sans-serif;
            padding: 3px 6px 3px 6px;
            width: max-content;
            opacity: 0;
            transition: 500ms ease;
        }
        
        &:hover{
            &::after{
                opacity: 1;
            }
        }
    }
}