@import '../../css/style.scss';

.container-login{
    display: flex;
    width: 100%;

    .container-bg{
        display: flex;
        width: 55%;
        min-height: 100vh;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../../assets/login-texto.jpg');
        @include responsive(s){
            width: auto;
            background-position: center 54%;
            min-height: 25vh;
        }
    }

    @include responsive(s) {
        flex-direction: column;
    }

    .box-apresentation{
        width: 30vw;
        @include responsive(l){
            margin-top: 40px;
        };
        @include responsive(s){
            width: fit-content;
        }
        @include responsive(xs){
            margin-top: 20px;
        }

        .title{
            @include responsive(xs){
                margin-top: 30px;
            }
        }
    }

    .logo-invisible{
        display: flex;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: relative;
        bottom: 4.5vh;
        margin-left: auto;
    }
}


.box-interation{
    display: flex;
    flex-direction: column;
    text-align: center;

    .cadastro{
        cursor: pointer;
        display: block;
        color: $black;
        opacity: 0.4;
        margin: 40px 0px 20px 0;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        &:hover {
            text-decoration: underline;
        }

        @include responsive(l){
            margin-top: 15px;
        };

        @include responsive(s){
            font-size: 21px;
            margin-bottom: 10px;
        }   
    }
    
    p{
        color: $black;
        opacity: 0.4;
        margin: 0;
        display: inline-block;
    }
}