@import '../../css/style.scss';


.container-card-questionarioResultado{
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 7px;
    filter: drop-shadow(0px 1px 9px #ECECEC);
    


    .box-show-question{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 14px 21px 14px 43px;
        justify-content: space-between;
        border-radius: 7px;

        @include responsive(l){
            padding: 8px 21px 8px 43px;
        }
        
        @include responsive(xs){
            padding: 8px 8px 8px 8px;
        }

        p{
            font-weight: bold;
            font-size: 22px;

            @include responsive(l){
                font-size: 16px;
            }

            @include responsive(xs){
                font-size: 13px;
                max-width: 90%;
            }
        }

        .icon-click{
            width: fit-content;
            fill: #727272;
            height: 30px;
        }
    }

    form{
        padding: 1rem 3.5rem; 
        position: relative;

        @include responsive(xs){
            padding: 1rem 0.5rem;
        }

        .btn-question{
            width: 130px;
            height: 34px;
            background-color: var(--color-tema);
            border-radius: 15px;
            color: white;
            border: none;
            position: absolute;
            right: 10%;
            transition: 400ms ease;

            &:hover{
                transform: translateY(-5px);
            }
        }
    }

    .box-show-question2{
        background-color: var(--color-tema);
        color: $white-bg;

         .icon-click{
             fill: $white-bg;
            transform: rotateZ(88deg);
         }
    }
}

.show{
    padding-bottom: 28px;
}

.addBorder{
    border-left: 2px solid red;
}