*, ::before, ::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

:root {
  --color-tema: green;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  position: relative;
}

img, svg {
  display: block;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: #000000;
}

button {
  cursor: pointer;
}

.style-uppercase {
  text-transform: uppercase;
}

.style-lowercase {
  text-transform: lowercase;
}

.show {
  height: -moz-fit-content;
  height: fit-content;
}

.btn-back {
  position: relative;
  transition: all 500ms ease;
}
.btn-back::after {
  content: "";
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: black;
  opacity: 0;
}
.btn-back:hover::after {
  opacity: 0.6;
}

.img-wallpaper {
  width: 55vw;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 960px) {
  .img-wallpaper {
    display: none;
  }
}

.login__box-left {
  display: flex;
  width: 45%;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
  flex-direction: column;
  margin-left: auto;
}
@media (max-width: 960px) {
  .login__box-left {
    width: 100%;
  }
}

.title {
  font-size: 57px;
  font-weight: 400;
  margin-bottom: 35px;
  color: #679FDD;
}
@media (max-width: 1440px) {
  .title {
    font-size: 35px;
  }
}
@media (max-width: 1140px) {
  .title {
    font-size: 40px;
  }
}
@media (max-width: 960px) {
  .title {
    font-size: 40px;
  }
}
@media (max-width: 540px) {
  .title {
    font-size: 30px;
  }
}

.subtitle {
  margin-bottom: 40px;
}
@media (max-width: 1440px) {
  .subtitle {
    margin-bottom: 30px;
  }
}
@media (max-width: 960px) {
  .subtitle {
    font-size: 22px;
  }
}
@media (max-width: 540px) {
  .subtitle {
    font-size: 16px;
  }
}

.logo-abrafati {
  width: 115px;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 13px;
}
@media (max-width: 1440px) {
  .logo-abrafati {
    width: 85px;
  }
}/*# sourceMappingURL=style.css.map */