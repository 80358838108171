@import '../../css/style.scss';

.box-subtema{
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    color: $gray-3;
    box-shadow: 5px 2px 10px #0000000D;
    border-radius: 7px;
    padding: 10px 8px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    p{
        @include responsive(xs){
            font-size: 14px;
            max-width: 84%;
        }
    }    
    
    @include responsive(l){
        font-size: 16px;
    }

    @include responsive(s){
        font-size: 16px;
    }

    .grafico-rosca-resposta{ 
        position: absolute;
        right: 6%;
        top: calc(50% - 17px);
        height: 34px;
        width: 34px;
        background-color: $color-progress;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include responsive(xs){
            right: 10%;
            top: calc(50% - 15px);
        }

        .value-progress{
            position: relative;
            font-size: 12px;
            font-weight: bold;
            z-index: 15;

            @include responsive(l){
                font-size: 11px;
            }
        }
        
        &::after{
            content: '';
            width: 90%;
            height: 90%;
            position: absolute;
            top: calc(50% - 15px);
            right: calc(50% - 15.295px);
            background-color: #ffffff;
            border-radius: 50%;

        }
    }

    .icon-iteration{
        width: fit-content;
        height: 22px;
        fill: #679FDD;

        @include responsive(xs){
            transform: translateY(calc(50% - 11px));
        }
    }
}