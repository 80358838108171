@import '../../css/style.scss';
.popup-warn {
    
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: right;
    z-index: 1000;
    img {
        width: 15px;
        height: 15px;
        filter: invert(1);
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    &-content {
        background: #81A6CF;
        border-radius: 8px 8px 0 0;
        padding: 30px 40px;
        max-width: 440px;
        width: 100%;
        text-align: center;
        box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
        margin-right: 270px;
        z-index: 9999;
        white-space: nowrap;
        font-size: 19px;
        font-weight: 700;
        color: white;
        position: relative;
        @media (max-width: 960px) {
            margin: 0 auto;
            max-width: 340px;
            font-size: 16px;
            padding: 30px 10px;
        
        }
    }

    .progress-bar {
        height: 5px;
        background-color: #5B97DB;
        border-radius: 0 0 8px 8px;
        margin-top: 10px;
        transition: width 0.1s linear;
    }

    &.slide-up {
        animation: slide-up 0.5s ease-in-out forwards;
    }

    &.slide-down {
        animation: slide-down 0.5s ease-in-out forwards;
    }
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-down {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
 
    .popup-inner {
        position: relative;
        padding: 32px;
        width: 100%;
        max-width: 75vw;
        max-height: 90vh;
        overflow-y: scroll;
        background-color: white;

        @include responsive(s) {
            max-width: 90vw;
        }

        .close-btn {
            all: unset;
            position: absolute;
            top: 40px;
            right: 40px;
            width: 2rem;
            height: 1.5rem;
            transition: .2s ease-in-out;

            &:hover {
                cursor: pointer;
                transform: scale(1.2);
            }
        }
    }
}