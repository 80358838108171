@import '../../css/style.scss';

.card-tema{
    width: 90%;
    height: fit-content;
    padding: 45px 10px 30px 40px;
    display: flex;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 40px #0000000D;
    margin-bottom: 50px;

    @include responsive(s){
        padding-left: 10px;
    }

    @include responsive(xs){
        width: 90%;
        flex-direction: column;
        align-items: center;
        padding-bottom: 54px;
    }

    .card-tema__box-left{
        width: 40%;

        @include responsive(s){
            width: 100%;
            margin-bottom: 20px;
        }

        h2{
            font-size: 25px;
            margin-bottom: 8px;

            @include responsive(l){
                font-size: 18px;
            }

            @include responsive(s){
                margin: 10px 0px 10px 0px;
            }

            @include responsive(xs){
                margin-bottom: 10px;
            }
        }

        .card-tema__description{
            font-size: 20px;
            line-height: 130%;

            @include responsive(l){
                font-size: 14px;
            }
        }
        
        img{
            width: 80%;
            height: auto;
            transform: translateY(-8px);
            position: relative;
            z-index: 3;

            @include responsive(s){
                transform: translate(-22px, -14px);
            }
        }

        .box-img-card-tema{
            position: relative;
            width: fit-content;
            height: fit-content;
            
            > img{
                border-radius: 0px 23px 23px 23px;
            }
        }

        #color-1, #color-5{
            color: $color-gov;
        }
        #color-2, #color-6{
            color: $color-ch;
        }
        #color-3, #color-7{
            color: $color-cs;
        }
        #color-4, #color-8{
            color: $color-meioAm;
        }
    }

    .card-tema__box-rigth{
        width: 60%;
        padding: 10px;
        position: relative;
        transform: translateY(-25px);


        @include responsive(s){
            width: 100%;
        }

        .box-percentual-concluido{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            h2{
                color: $gray-4;
                font-size: 30px;

                @include responsive(l){
                    font-size: 24px;
                }
            }

            p{
                color: $gray-4;
                margin-bottom: 4px;
            }
            
            progress{
                width: 90%;
                height: 20px;
                -webkit-appearance: none;
                margin-bottom: 20px;

                @include responsive(l){
                    height: 12px;
                }
            }

            ::-webkit-progress-bar {
                background-color: $gray-2;
                border-radius: 15px;
            }

            ::-webkit-progress-value {
                border-radius: 15px;
                background-color: $color-progress;
            }   
        }

        .card-tema__btn{
            color: $white-bg;
            border: none;
            padding: 12px 62px;
            margin: 15px 0 0 0;
            margin-top: 15px;
            border-radius: 15px;
            
            position: absolute;
            right: 8px;
            cursor: pointer;
        }
        

        #bg-1, #bg-5{
            background-color: $color-gov;
        }
        #bg-2, #bg-6{
            background-color: $color-ch;
        }
        #bg-3, #bg-7{
            background-color: $color-cs;
        }
        #bg-4, #bg-8{
            background-color: $color-meioAm;
        }  
    }
}