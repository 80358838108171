@import '../../css/style.scss';

.container-cadastro2{
    display: flex;
    width: 100%;

    .container-bg {
        display: flex;
        width: 55%;
        min-height: 100vh;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../../assets/login-texto.jpg');

        @include responsive(s) {
            width: auto;
            background-position: center 54%;
            min-height: 25vh;
        }
    }

    @include responsive(s) {
        flex-direction: column;
    }

    .title{
        margin: 100px 0 25px 0;

        @include responsive(l){
            margin: 70px 0 20px 0;
        }

        @include responsive(xs){
            width: fit-content;
        }
    }
    

    form{
        width: 70%;

        @include responsive(xs){
            width: 90%;
        }
    }

    .alert-erro-phone{
        position: relative;
        color: red;
        opacity: 1;
        display: none;
        
        @include responsive(l){
            font-size: 14px;
        }
    }

    .btn{
        margin: 35px 0 15px 0;

        @include responsive(l){
            margin: 30px 0 15px 0;
        }

        @include responsive(s){
            margin-top: 55px;
        }
    }

    a{
        color: $gray-place;
        opacity: 50%;
        padding: .3rem;
    }

    .logo-abrafati{
        margin-top: 50px;

        @include responsive(xs){
            margin-bottom: 20px;
        }
    }

    .container-select-state{
        outline: none;
        border: none;
        width: 100%;
        background-color: white;
        border-left: 3px solid #679FDD;
        border-bottom: 2px solid #ECECEC;
        color: #BFBFBF;
        padding: 7px;
        font-size: 16px;
        margin: 30px 0 5px 0;

        @include responsive(s){
            width: 55vw;
        }

        @include responsive(xs){
            width: 100%;
        }
    }

    .alert-erro-state{
        position: relative;
        color: red;
        opacity: 1;
        display: none;
        
        @include responsive(l){
            font-size: 14px;
        }
    }

    
}