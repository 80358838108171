@import '../../css/style.scss';

.container-renew-password{
    display: flex;
    width: 100vw;
    height: 100vh;
    .container-loading {
        background-color: #00000073;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    @include responsive(s){
        width: 100vw;
        height: fit-content;
    }

    .box-apresentation{

        .title{
            width: 30vw;
            margin: 100px 0 25px 0;
    
            @include responsive(l){
                margin: 55px 0 20px 0;
            }
            
            @include responsive(s){
                width: fit-content;
            }
    
            @include responsive(xs){
                width: fit-content;
            }
        }

        .subtitle{
            color: black;

            @include responsive(l){
                margin-bottom: 0px;
            }
        }
    }

    .renew-password__form-renew{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 45vw;
        text-align: start;
        overflow: hidden;

        @include responsive(s){
            width: 100%;
        }

        .btn{
            margin: 35px 0 15px 0;

            @include responsive(l){
                margin: 30px 0 2rem 0;
            }
        }

        .logo-abrafati{
            margin: 0px auto;
        }
    }

}