@import '../../css/style.scss';


.container-renovar-senha{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45vw;
    text-align: start;
    overflow: hidden;
    position: relative;
    margin-left: auto;

    .container-loading {
        background-color: #00000073;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    @include responsive(s){
        width: 100%;
    }

    .required{
        color: $blue-button;
        bottom: -22px;

        @include responsive(m){
            bottom: -40px;
        }

        @include responsive(s){
            bottom: -22px;
        }

        @include responsive(xs){
            bottom: -38px;
        }
    }

    .logo-abrafati{
        margin: 0 auto;
    }

    input{
        @include responsive(xs){
            width: 90%;
        }
    }
    
    .box-interation{
        margin-top: 45px;
    }
}


.box-return-login{
    margin: 10px 0 60px 0;
    @include responsive(s){
        margin-bottom: 30px;
    }
    p{
        font-size: 23px;
        color: $black;
        margin-right: 3px;
        @include responsive(l){
            font-size: 18px;
        };
        @include responsive(s){
            font-size: 20px;
        }
    }
}

.btn-return-login{
    display: inline-block;
    color: black;
    position: relative;
    transition: all 400ms ease;
    cursor: pointer;

    @include responsive(s){
        font-size: 20px;
    }

    &::after{
        content: '';
        position: absolute;
        bottom: -1px;
        right: 0;
        width: 100%;
        height: 1px;
        background-color: black;
        opacity: 0;
    }

    &:hover{
        &::after{
            opacity: 0.6;
        }
    }
}