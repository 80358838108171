@import '../../css/style.scss';

.card-relatorio{
    display: flex;
    flex-direction: column;
    width: 93%;
    margin-top: 38px;
    padding-left: 48px;
    position: relative;

    @include responsive(xs){
        padding: 0px;
        margin-top: 15px;
    }

    &::before{
        content: '';
        background-color: $gray-2;
        height: 85%;
        width: 3px;
        left: 0;
        position: absolute;

        @include responsive(xs){
            display: none;
        }
    }

    h4{
        font-size: 40px;
        margin-bottom: 36px;

        @include responsive(l){
            font-size: 20px;
        }
    }
    .card-relatorio__description{
        font-size: 22px;
        margin-bottom: 20px;

        @include responsive(l){
            font-size: 17px;
        }
    }

    .title-resultado-geral-setor{
        font-weight: bold;
        font-size: 19px;
        margin-bottom: 1rem;

        @include responsive(l){
            font-size: 17px;
        }
    }

    .pontuacao_empresa{
      margin-bottom: 15px;
      font-size: 19px;
        
      @include responsive(l){
        font-size: 16px;
      }

      >span{
        font-weight: 500;
      }
    }

    .pontuacao_total{
        margin-bottom: 15px;
        font-size: 16px;

        @include responsive(l){
            font-size: 12px;
        }

        >span{
            font-weight: bold;
        }
    }

    .card-relatorio__box-percentual{
        display: flex;
        width: 80%;
        margin-bottom: 25px;

        @include responsive(s){
            width: 100%;
        }

        @include responsive(xs){
            margin-bottom: 28px;
            flex-direction: column-reverse;
        }

        .scale-progress{
            width: 51%;
            height: 30px;
            margin-right: 25px;
            background-color: #b5cfff;
            border-radius: 15px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            position: relative;
            z-index: 2;

            @include responsive(l){
                height: 25px;
            }

            @include responsive(xs){
                width: 94%;
                margin-bottom: 10px;
            }

            .scale-progress__fill-bg{
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                background-color: #fe9454;
                border-radius: 15px;
                z-index: 1;
            }

            .description-stages{
                display: flex;
                width: 100%;
                justify-content: space-between;
                position: absolute;
                bottom: -18px;
                left: 0;

                p{
                    font-size: 14px;
                    
                    &:nth-child(1){
                        margin-left: 3px;
                    }
                    &:nth-child(4){
                        margin-right: 3px;
                    }
                }
            }
        }

        .relatorio__company-result-percent{
            font-size: 50px;
            font-weight: bold;
            color: #000000;
            margin-right: 100px;
            position: relative;
            transform: translateY(-16px);

            @include responsive(l){
                font-size: 24px;
                transform: translateY(0px);
            }

            @include responsive(s){
                margin-right: 10px;
            }
    
            @include responsive(xs){
                margin-right: 0px;
                margin-bottom: 10px;
            }

            &::after{
                content: '';
                background-color: $gray-2;
                height: 168%;
                width: 3px;
                right: -48px;
                bottom: -20px;
                position: absolute;

                @include responsive(s){
                    display: none;
                }
        
                @include responsive(xs){
                    display: none;
                }
            }
        }

        .relatorio__box-result{
            color: #fe9454;
            font-weight: 700;
            font-size: 28px;
            width: fit-content;
            transform: translateY(-15px);

            @include responsive(xs){
                margin-top: 10px;
            }

            p{
                width: max-content;

                @include responsive(l){
                    font-size: 18px;
                }

                @include responsive(xs){
                    font-size: 18px;
                }
            }

            .style-uppercase{
                font-size: 25px;

                &::after{
                    display: none;
                }

                @include responsive(l){
                    font-size: 16px;
                }

                @include responsive(xs){
                    font-size: 18px;
                }
            }

            @include responsive(l){
                font-size: 20px;
            }
        }
    }

    #bg-1{
        background-color: $color-gov;
    }
    #bg-2{
        background-color: $color-ch;
    }
    #bg-3{
        background-color: $color-cs;
    }
    #bg-4{
        background-color: $color-meioAm;
    }  

    #color-1{
        color: $color-gov;
    }
    #color-2{
        color: $color-ch;
    }
    #color-3{
        color: $color-cs;
    }
    #color-4{
        color: $color-meioAm;
    }
}