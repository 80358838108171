@import '../../css/style.scss';

.btn{
    width: 30vw;
    height: 64px;
    font-size: 23px;
    border: none;
    border-radius: 15px;
    color: $white-bg;
    background-color: $blue-button;
    transition: 400ms ease;

    @include responsive(l){
        height: 45px;
        font-size: 18px;
    };

    @include responsive(s){
        width: 55vw;
    }

    @include responsive(xs){
        width: 90%;
    }

    &:hover{
        opacity: 0.9;
    }
}