@import '../../css/style.scss';

.container-resultado-tema {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 120px;

    .resultado-tema__tema-title {
        margin-top: 100px;
    }

    .color-tema1,
    .color-tema5,
    .color-tema9 {
        color: $color-gov;
    }

    .color-tema2,
    .color-tema6,
    .color-tema10 {
        color: $color-ch;
    }

    .color-tema3,
    .color-tema7,
    .color-tema11 {
        color: $color-cs;
    }

    .color-tema4,
    .color-tema8,
    .color-tema12 {
        color: $color-meioAm;
    }
}