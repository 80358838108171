@import '../../css/style.scss';

.box-input{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 30px 0 5px 0;
    width: 100%;


    label{
        color: $black;
        font-size: 23px;
        text-align: start;

        @include responsive(l){
            font-size: 18px;
        };
    }

    input{
        all: unset;
        height: 32px;
        border-bottom: 2px solid $gray-2;
        padding: .5rem 0rem;
        margin-top: .4rem;
    
        @include responsive(s){
            width: 55vw;
        }

        @include responsive(xs){
            width: 100%;
        }

        &::placeholder {
            font-size: 18px;
            color: $gray-place;

            @include responsive(l){
                font-size: 18px;
            };
        }
    }  

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    .required{
        position: absolute;
        display: none;
        color: red;
        bottom: -24px;

        @include responsive(l){
            font-size: 14px;
        }
    }
}
