@import '../../css/style.scss';

.box-card-archive{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.6rem 0.4rem 1.6rem;
    margin: 0 0 0.4rem 0;
    width: 70%;
    height: 3rem;
    border-bottom: 1px solid $blue-title;
    color: $blue-title;
    font-weight: bold;

    @include responsive(xs){
        width: 97%;
        padding: 0 0.6rem 0 0.6rem;
        font-size: 12px;
    }

    svg{
        all: unset;
        display: inline-block;
        height: 20px;
        width: auto;
    }
}

.btn-archive{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 76%;
    padding: 6px;
    font-size: 14px;
    text-decoration: none;
    border: 1px solid $blue-title;
    color: $blue-title;
    border-radius: 3px;
    cursor: pointer;
    transition: 400ms ease;

    &:hover{
        background-color: $blue-title;
        color: #fff;
        transform: translateY(-1px);
    }

    @include responsive(l){
        font-size: 16px;
        padding: 8px;
    }

    @include responsive(xs){
        font-size: 15px;
        width: 6rem;
    }
}