@import '../../css/style.scss';

.container-card-perguntas{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 130px;

    .card-perguntas-title{
        font-size: 30px;
        color: var(--color-tema);
        font-weight: bold;
        margin-bottom: 80px;
        position: relative;

        @include responsive(l){
            font-size: 22px;
        }

        @include responsive(s){
            font-size: 26px;
        }

        @include responsive(xs){
            font-size: 22px;
            margin-bottom: 60px;
        }


        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $gray-2;
            bottom: -12px;
            left: 0;
        }
    }

    .card-perguntas__description{
        display: flex;
        padding: 0 10px 0 10px;

        @include responsive(s){
            flex-direction: column;
            align-items: center;
        }

        h5{
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 30px;

            @include responsive(l){
                font-size: 18px;
            }

            @include responsive(s){
                font-size: 22px;
            }

            @include responsive(s){
                font-size: 20px;
            }
        }

        p{
            font-size: 22px;

            @include responsive(l){
                font-size: 17px;
            }
        }

        .box-left{
            margin-right: 74px;
            position: relative;

            @include responsive(s){
                margin-right: 0px;
            }

            &::after{
                content: '';
                position: absolute;
                width: 2px;
                height: 70%;
                background-color: $gray-2;
                right: -33px;
                top: 0;
            }
        }

        .box-left, .box-rigth{
            width: 45%;
            margin-bottom: 50px;

            @include responsive(s){
                width: fit-content;

                p{
                    font-size: 23px;
                    
                    @include responsive(s){
                        font-size: 20px;
                    }

                    @include responsive(xs){
                        font-size: 16px;
                    }
                }
            }
        }

    }
}