:root{
    --color-tema: green;
}

$blue-title: #679FDD;
$blue-button: #5B97DB;
$blue-card-tema: #8DBFF6;
$black: #000000;

$white-bg: #FFFFFF;
$gray-place: #BFBFBF;
$gray-2: #ECECEC;
$gray-3: #7D7D7D;
$gray-4: #707070;
$gray-5: #ABABAB;
$gray-6: #606060;
$color-progress: #2AD3AA;


$color-gov: #E5918B;
$color-gov-2: #EB5C53;
$color-gov-3: #F4A28D;
$color-meioAm: #83B536;
$color-cs: #FCC525;
$color-ch: #5B97DB;


$purple-link: #47225E;
$purple-link-hover: #A25FEE;
