@import '../../css/style.scss';

.login__box-left{
    position: relative;
    align-items: center;
    justify-content: center;

    .container-loading{
        background-color: #00000073;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .success-message{
        color: $color-meioAm;
    }

    .error-message{
        color: $color-gov-2;
    }
    
    .logo-abrafati{
        margin: 0 auto;
    }

    input{
        @include responsive(xs){
            width: 90%;
        }
    }

    form{
        width: 65%;


        @include responsive(xs){
            width: 77%;
        }

        @include responsive(m){
            width: 52%;
        }
        @include responsive(l){
            width: 65%;
        }
    }
    

    .box-apresentation{
        width: 30vw;
        @include responsive(l){
            margin-top: 40px;
        };
        @include responsive(s){
            width: fit-content;
        }
        @include responsive(xs){
            margin-top: 20px;
        }

        .title{
            @include responsive(xs){
                margin-top: 30px;
            }
        }

        .subtitle{
            color: black;
            opacity: 1;
        }
    }

    .logo-invisible{
        display: flex;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: relative;
        bottom: 4.5vh;
        margin-left: auto;
        @include responsive(s){
            bottom: 31px;
            left: -69px;
        }
        @include responsive(m){
            bottom: 31px;
            left: 25px;
        }
        @include responsive(xs){
            bottom: 31px;
            left: 0;
        }
    }

    .box-renovar-senha{
        margin: 10px 0 40px 0;
        @include responsive(s){
            margin-bottom: 30px;
        }
        p{
            font-size: 23px;
            color: $black;
            margin-right: 3px;
            @include responsive(l){
                font-size: 18px;
            };
            @include responsive(s){
                font-size: 20px;
            }
        }
    } 

    .link-renovar-senha{
        display: inline-block;
        color: black;
        position: relative;
        transition: all 400ms ease;
        cursor: pointer;

        @include responsive(s){
            font-size: 20px;
        }

        &::after{
            content: '';
            position: absolute;
            bottom: -1px;
            right: 0;
            width: 100%;
            height: 1px;
            background-color: black;
            opacity: 0;
        }

        &:hover{
            &::after{
                opacity: 0.6;
            }
        }
    } 

    p{
        color: $black;
        opacity: 0.4;
        margin: 0;
        display: inline-block;
    }
}