@import '../../css/style.scss';

.box-previous-page{
    display: flex;
    width: fit-content;
    text-align: center;
    color: $color-gov-2;
    font-weight: 700;
    margin: 156px 0px 28px 15px;
    position: relative;
    cursor: pointer;

    .icon-iteration{
        transform: rotate(180deg) translate(-20px, 6px);

        @include responsive(l){
            font-size: 20px;
            transform: rotate(180deg) translate(-10px, 6px);
        }

        svg{
            height: 46px;
            color: var(--color-tema);

            @include responsive(l){
                height: 30px;
            }
        }
    }

    .title-perguntas{
        font-size: 30px;
        margin-bottom: 10px;
        margin-left: 15px;
        color: var(--color-tema);
        position: relative;

        @include responsive(l){
            font-size: 20px;
            margin-left: 10px;
        }
        
        @include responsive(s){
            font-size: 26px;
        }

        @include responsive(s){
            font-size: 20px;
        }

        &::after{
            content: '';
            position: absolute;
            width: 99%;
            height: 2px;
            right: 0px;
            bottom: -5px;
            background-color: transparent;
            border: 1px solid $gray-2;
            border-style: dashed;
        }
    }   
}

.card-resumo-perguntas{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 40px #00000021;
    border-radius: 0px 45px 45px 0px;
    position: relative;

    @include responsive(s){
        flex-direction: column;
    }

    &::before{
        content: '';
        position: absolute;
        width: 90%;
        height: 90%;
        border: 2px solid var(--color-tema);
        top: 18px;
        left: 18px;
        z-index: -1;

        @include responsive(s){
            width: 95%;
            border-radius: 0px 45px 45px 0px;
        }

        @include responsive(xs){
            width: 90%;
        }
    }

    .perguntas__box-left, .perguntas__box-rigth{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 25px 0;
    }

    .perguntas__box-left{
        width: 40%;
        justify-content: space-evenly;

        h3{
            font-size: 23px;
            font-weight: normal;
            margin-top: 5%;

            @include responsive(l){
                font-size: 14px;
            }

            @include responsive(m){
                margin-top: 10%;
            }

            @include responsive(s){
                font-size: 16px;
                margin-bottom: 5%;
            }
        }

        .grafico-rosca-resposta{ 
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 200px;
            width: 200px;
            background-color: $color-progress;
            border-radius: 50%;
            margin-bottom: 7%;

            .value-progress{
                font-size: 52px;
                font-weight: bold;
                z-index: 15;

                @include responsive(l){
                    font-size: 42px;
                }
            }
            
            &::after{
                content: '';
                position: absolute;
                top: calc(55% - 100px);
                right: calc(52% - 94px);
                width: 90%;
                height: 90%;
                background-color: #ffffff;
                border-radius: 50%;
            }
        }

        @include responsive(s){
            width: 100%;
        }
    }

    .perguntas__box-rigth{
        width: 60%;
        border-radius: 0px 45px 45px 45px;
        background-color: var(--color-tema);
        color: $white-bg;
        padding-top: 70px;
        padding-bottom: 14px;
        align-items: center;

        @include responsive(l){
            padding-top: 50px;
        }

        .box-img-perguntas{
            width: 100%;
            height: 68%;
            position: relative;
            z-index: 2;

            img{
                object-fit: cover;
                border-radius: 23px;
                transform: translateY(-0.5rem);
            }
        }
        
        p{
            font-weight: normal;
            margin-top: 20px;

            @include responsive(l){
                margin-top: 38px;
                font-size: 17px;
            }

            @include responsive(s){
                font-size: 19px;
                margin-bottom: 17px;
            }

            @include responsive(xs){
                font-size: 18px;
                margin-bottom: 17px;
            }
        }

        @include responsive(s){
            width: 100%;
        } 
    }
}