@import '../../css/style.scss';

.container-relatorio-conjunto {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 22;
  background: white;
  width: 100%;
  padding-top: 30px;
  justify-content: center;
  align-items: center;

  .logo-abrafati-print {
    width: 95px;
    height: fit-content;
    margin: 0 auto;

    @include responsive(m) {
      width: 90px;
    }
  }

  .btn-print {
    margin: 0 auto;
    padding: 14px 44px;
    border-radius: 15px;
    border: none;
    margin-bottom: 30px;
    color: $white-bg;
    background-color: $blue-button;
    transition: all 400ms ease;

    &:hover {
      transform: translateY(-5px);
      opacity: 0.9;
    }
  }

  .main-relatorio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    margin-top: 0;
  }

  .test-class {
    transform: translate(78px, -21px) !important;

    @include responsive(l) {
      transform: translate(98px, 140px) !important;
    }
  }

  .container-relatorio .main-relatorio .container-graph .highcharts-axis-labels {
    text {
      &:nth-child(1) {
        transform: translate(40px, -47px) !important;

        @include responsive(l) {
          transform: translate(26px, -39px) !important;
        }
      }

      &:nth-child(17) {
        transform: translate(-6px, -46px);

        @include responsive(l) {
          transform: translate(-4px, -35px);
        }
      }

      // &:nth-child(9){
      //   transform: translate(5px, 38px);
      // }
    }
  }

  .container-graph__legenda-explicativa {
    left: -590px !important;
  }

  .box-relatorio-congratulations {
    margin-bottom: 0 !important;
  }

  .resultado-tema__tema-title {
    margin-top: 20px;
  }

  .perguntas-resultado__title {
    margin-top: 25px;
  }
}

@media print {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body * {
    visibility: hidden;
    -webkit-print-color-adjust: exact;
  }

  .no-print {
    display: none;
  }

  .imprimir,
  .imprimir * {
    visibility: visible;
  }

  .imprimir {
    position: fixed;
    left: 0;
    top: 0;
  }

  .container-relatorio {
    &.downloads {
      break-before: auto !important;
    }

    .main-relatorio {
      margin: 0 !important;
      padding: 0 !important;

      .container-graph__legenda-explicativa {
        &.resultados-print {
          left: -200px !important;
          top: -900px;
        }
      }
    }
  }

  .box-relatorio-congratulations {
    margin: 0 !important;
    padding: 0 !important;

    &.conjunto {
      &.downloads {
        break-before: auto !important;
        page-break-before: auto !important;
        break-after: auto !important;
        page-break-after: auto !important;
        margin: 0 !important;
        top: -800px !important;
      }
    }
  }

  .container-graph {
    width: 420px !important;
    height: 881px !important;

    &>img {
      width: 560px !important;
      height: 560px !important;
    }
  }
}