*, ::before, ::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

@import 'partials/colors';
@import './partials/responsive';

html{
    scroll-behavior: smooth;
}

body{
    width: 100%;
    height: 100%;
    background-color: $white-bg;
    position: relative;
}

img, svg{
    display: block;
    width: 100%;
    height: 100%;
}

a{
    text-decoration: none;
    color: $black;
}

button{
    cursor: pointer;
}

.style-uppercase{
    text-transform: uppercase;
}

.style-lowercase{
    text-transform: lowercase;
}

.show{
    height: fit-content;
}

.btn-back{
    position: relative;
    transition: all 500ms ease;

    &::after{
        content: '';
        position: absolute;
        bottom: -1px;
        right: 0;
        width: 100%;
        height: 1px;
        background-color: black;
        opacity: 0;
    }

    &:hover{
        &::after{
            opacity: 0.6;
        }
    }
}

.img-wallpaper{
    width: 55vw;
    object-fit: cover;

    @include responsive(s){
        display: none;
    }
}

.login__box-left {
    display: flex;
    width: 45%;
    height: fit-content;
    min-height: 100vh;
    flex-direction: column;
    margin-left: auto;

    @include responsive(s){
        width: 100%;
    }
}


.title{
    font-size: 57px;
    font-weight: 400;
    margin-bottom: 35px;
    color: $blue-title;

    @include responsive(l){
        font-size: 35px;
    };

    @include responsive(m){
        font-size: 40px;
    };

    @include responsive(s){
        font-size: 40px;
    }

    @include responsive(xs){
        font-size: 30px;
    }  
}

.subtitle{
    margin-bottom: 40px;

    @include responsive(l){
        margin-bottom: 30px;
    };

    @include responsive(s){
        font-size: 22px;
    }

    @include responsive(xs){
        font-size: 16px;
    }
}

.logo-abrafati{
    width: 115px;
    height: fit-content;
    margin-top: 13px;  
    
    @include responsive(l){
        width: 85px;
    };
}

