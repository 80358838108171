@import '../../css/style.scss';

.container-relatorio {
    .break-page {
        break-after: page;
        page-break-after: always;
    }

    .main-relatorio {
        margin-left: 14vw;
        margin-top: 25vh;
        padding-left: 35px;
        padding-bottom: 120px;
        position: relative;
        z-index: 6;

        @include responsive(s) {
            padding: 0px;
            padding-bottom: 120px;
            margin: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .logo-abrafati-print {
            width: 95px;
            height: fit-content;
            margin-top: 50px;
            display: none;

            @include responsive(m) {
                width: 90px;
            }
        }


        .title {
            font-size: 30px;
            font-weight: bold;
            margin: 105px 0 10px 10px;

            @include responsive(l) {
                font-size: 20px;
            }

            @include responsive(s) {
                margin-top: 140px;
            }

            @include responsive(xs) {
                margin-top: 170px;
                font-size: 18px;
                margin-right: 10px;
            }
        }

        .relatorio__description {
            width: 88%;
            font-size: 22px;
            margin-left: 10px;

            @include responsive(l) {
                font-size: 17px;
            }
        }

        .relatorio__container-results {
            width: 88%;
            margin-top: 70px;
            box-shadow: 0px 0px 40px #0000000D;
            border-radius: 52px 52px 0px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include responsive(xs) {
                width: 95%;
            }

            .relatorio__box-description {
                width: 100%;
                padding: 15px 120px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: $blue-card-tema;
                color: $white-bg;
                border-radius: 52px 52px 0px 0px;

                @include responsive(s) {
                    padding: 15px 18px;
                }

                h3 {
                    font-size: 30px;
                    margin-top: 45px;

                    @include responsive(l) {
                        font-size: 21px;
                    }

                    @include responsive(s) {
                        font-size: 18px;
                    }
                }

                h4 {
                    font-size: 30px;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    @include responsive(l) {
                        font-size: 20px;
                    }
                }

                p {
                    font-size: 20px;
                    margin: 10px 0 10px 0;

                    @include responsive(l) {
                        font-size: 16px;
                    }
                }
            }

        }

        .box-relatorio-subtema {
            width: 88%;
            background-color: $blue-card-tema;
            color: $white-bg;
            border-radius: 0px 0px 35px 35px;
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 15px 151px;

            @include responsive(s) {
                text-align: center;
                padding: 20px 11px;
                margin-left: 0px;
                width: 90%;
            }

            @include responsive(xs) {
                margin-bottom: 0px;
            }

            h4 {
                font-size: 30px;
                margin-top: 20px;

                @include responsive(l) {
                    font-size: 20px;
                }

                @include responsive(s) {
                    margin-top: 20px;
                }
            }

            p {
                font-size: 20px;

                &:last-child {
                    font-size: 23px;
                }

                @include responsive(s) {
                    margin: 16px 0 0 0;
                }

                &:nth-child(2) {
                    margin: 30px 0 20px 0;
                    font-size: 20px;

                    @include responsive(l) {
                        font-size: 16px;
                    }
                }
            }
        }

        .box-relatorio-congratulations {
            width: 88%;
            background-color: $color-progress;
            color: $white-bg;
            border-radius: 0px 35px 35px 35px;
            margin: -90px 0 100px 45px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 32px 151px;
            position: relative;

            @include responsive(l) {
                padding: 28px 130px;
            }

            @include responsive(s) {
                width: 90%;
                text-align: center;
                padding: 26px 11px;
                margin-top: 30px;
                margin-left: 0px;
            }

            h4 {
                font-size: 50px;
                font-weight: normal;
                margin-bottom: 65px;

                @include responsive(l) {
                    font-size: 25px;
                    margin-bottom: 45px;
                }

                @include responsive(s) {
                    margin-bottom: 40px;
                }
            }

            p {
                font-size: 22px;
                text-align: center;

                @include responsive(l) {
                    font-size: 17px;
                }
            }

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: $gray-2;
                top: 137px;
                right: 95;

                @include responsive(l) {
                    top: 45%;
                }
            }
        }

        .relatorio__img-grafico {
            width: 75%;
            margin: 0 auto;
        }

        .container-graph {
            width: 84%;
            height: 800px;
            margin-bottom: 30px;
            // margin-left: 3vw;
            position: relative;
            right: -144px;

            &.center-graph {
                right: 0;
            }

            @include responsive(l) {
                transform: translateY(-90px);
            }

            .highcharts-container {
                margin: 35px auto;
                float: none;
            }

            .highcharts-point {
                opacity: 0.9;
            }

            @include responsive(s) {
                width: 80%;
                right: auto;
            }

            @include responsive(xs) {
                width: 96%;
                margin-left: 0;
                margin-bottom: 0;
                right: auto;
            }

            >img {
                position: absolute;
                z-index: -1;
                top: calc(50% - 390px);
                right: calc(50% - 390px);
                width: 639px;
                height: 639px;
                transform: translate(-35px, 29px);

                @include responsive(l) {
                    width: 546px;
                    height: 546px;
                    transform: translate(-19px, 136px);
                }

                @include responsive(s) {
                    top: calc(50% - 330px);
                    right: calc(50% - 330px);
                    width: 640px;
                    height: 640px;
                    transform: translate(-32px, 127px);
                }

                @include responsive(xs) {
                    all: unset;
                    position: absolute;
                    z-index: -1;
                    top: calc(77% - 50%);
                    width: 370px;
                    right: calc(50% - 185px);
                    height: 370px;
                }

                @include responsive(xss) {
                    transform: translateY(-3px);
                    top: calc(79% - 50%);
                    width: 350px;
                    right: calc(50% - 175px);
                    height: 346px;
                }

            }

            .highcharts-legend,
            .highcharts-credits,
            .highcharts-no-tooltip,
            .highcharts-title {
                display: none;
            }

            .highcharts-xaxis-grid {
                display: none;
            }


            .highcharts-axis-labels {
                text {
                    fill: black !important;
                    font-weight: 500;
                    font-size: 14px !important;
                    opacity: 1;

                    @include responsive(l) {
                        font-size: 13px !important;
                    }

                    @include responsive(xs) {
                        font-size: 11px !important;
                        font-weight: normal;
                    }

                    &:nth-child(1) {
                        transform: translate(61px, -29px);

                        @include responsive(l) {
                            transform: translate(40px, -28px);
                        }

                        @include responsive(s) {
                            transform: translate(50px, -23px);
                        }

                        @include responsive(xs) {
                            transform: translate(44px, -32px);
                        }

                        @include responsive(xss) {
                            transform: translate(42px, -33px);
                        }
                    }

                    &:nth-child(2) {
                        transform: translate(32px, -14px);

                        @include responsive(l) {
                            transform: translate(26px, -20px);
                        }

                        @include responsive(s) {
                            transform: translate(22px, -16px);
                        }

                        @include responsive(xs) {
                            transform: translate(23px, -15px);
                        }

                        @include responsive(xss) {
                            transform: translate(29px, -26px);
                        }
                    }

                    &:nth-child(3) {
                        transform: translate(29px, 0px);

                        @include responsive(l) {
                            transform: translate(20px, -15px);
                        }

                        @include responsive(s) {
                            transform: translate(19px, 0px);
                        }

                        @include responsive(xs) {
                            transform: translate(18px, -15px);
                        }

                        @include responsive(xss) {
                            transform: translate(29px, -15px);
                        }
                    }

                    &:nth-child(4) {
                        transform: translate(25px, -1px);

                        @include responsive(l) {
                            transform: translate(15px, -10px);
                        }

                        @include responsive(s) {
                            transform: translate(18px, 5px);
                        }

                        @include responsive(xs) {
                            transform: translate(12px, -10px);
                        }

                        @include responsive(xss) {
                            transform: translate(23px, -10px);
                        }
                    }

                    &:nth-child(5) {
                        transform: translate(24px, 5px);

                        @include responsive(l) {
                            transform: translate(18px, -6px);
                        }

                        @include responsive(s) {
                            transform: translate(19px, 7px);
                        }

                        @include responsive(xs) {
                            transform: translate(15px, -6px);
                        }

                        @include responsive(xss) {
                            transform: translate(24px, -6px);
                        }
                    }

                    &:nth-child(6) {
                        transform: translate(23px, 22px);

                        @include responsive(l) {
                            transform: translate(15px, 8px);
                        }

                        @include responsive(s) {
                            transform: translate(17px, 15px);
                        }

                        @include responsive(xs) {
                            transform: translate(15px, 8px);
                        }

                        @include responsive(xss) {
                            transform: translate(24px, 8px);
                        }
                    }

                    &:nth-child(7) {
                        transform: translate(26px, 17px);

                        @include responsive(l) {
                            transform: translate(11px, 15px);
                        }

                        @include responsive(s) {
                            transform: translate(18px, 15px);
                        }

                        @include responsive(xs) {
                            transform: translate(13px, 7px);
                        }

                        @include responsive(xss) {
                            transform: translate(25px, 7px);
                        }
                    }

                    &:nth-child(8) {
                        transform: translate(18px, 34px);

                        @include responsive(l) {
                            transform: translate(10px, 25px);
                        }

                        @include responsive(s) {
                            transform: translate(18px, 25px);
                        }

                        @include responsive(xs) {
                            transform: translate(13px, 7px);
                        }

                        @include responsive(xss) {
                            transform: translate(25px, 7px);
                        }
                    }

                    &:nth-child(9) {
                        transform: translate(-35px, 65px);

                        @include responsive(l) {
                            transform: translate(-29px, 38px);
                        }

                        @include responsive(s) {
                            transform: translate(-34px, 56px);
                        }

                        @include responsive(xs) {
                            transform: translate(5px, 45px);
                        }
                    }

                    &:nth-child(10) {
                        transform: translate(17px, 63px);

                        @include responsive(l) {
                            transform: translate(23px, 49px);
                        }

                        @include responsive(s) {
                            transform: translate(23px, 53px);
                        }
                    }

                    &:nth-child(11) {
                        transform: translate(-19px, 24px);

                        @include responsive(l) {
                            transform: translate(-15px, 19px);
                        }

                        @include responsive(s) {
                            transform: translate(-15px, 17px);
                        }
                    }

                    &:nth-child(12) {
                        transform: translate(-19px, 12px);

                        @include responsive(l) {
                            transform: translate(-13px, 12px);
                        }

                        @include responsive(s) {
                            transform: translate(-14px, 12px);
                        }

                        @include responsive(xss) {
                            transform: translate(-20px, 12px);
                        }
                    }

                    &:nth-child(13) {
                        transform: translate(-19px, 4px);

                        @include responsive(l) {
                            transform: translate(-20px, 4px);
                        }

                        @include responsive(s) {
                            transform: translate(-18px, 4px);
                        }

                        @include responsive(xss) {
                            transform: translate(-24px, 4px);
                        }
                    }

                    &:nth-child(14) {
                        transform: translate(-22px, 2px);

                        @include responsive(l) {
                            transform: translate(-16px, -8px);
                        }

                        @include responsive(s) {
                            transform: translate(-19px, -8px);
                        }

                        @include responsive(xss) {
                            transform: translate(-25px, -8px);
                        }
                    }

                    &:nth-child(15) {
                        transform: translate(-22px, -4px);

                        @include responsive(l) {
                            transform: translate(-20px, -15px);
                        }

                        @include responsive(s) {
                            transform: translate(-16px, -15px);
                        }
                    }

                    &:nth-child(16) {
                        transform: translate(-27px, -16px);

                        @include responsive(l) {
                            transform: translate(-20px, -25px);
                        }

                        @include responsive(s) {
                            transform: translate(-15px, -25px);
                        }
                    }

                    &:nth-child(17) {
                        transform: translate(-6px, -45px);

                        @include responsive(l) {
                            transform: translate(-6px, -39px);
                        }

                        @include responsive(s) {
                            transform: translate(-6px, -39px);
                        }
                    }

                    &:nth-child(18) {
                        transform: translate(-1px, -5px);
                    }

                    &:nth-of-type(18) {
                        display: none !important;
                    }
                }

            }

            .highcharts-yaxis-labels {

                text {

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        display: none !important;

                    }
                }

            }

            .highcharts-axis-labels .highcharts-yaxis-labels .highcharts-radial-axis-labels {
                text {
                    &:nth-child(2) {
                        fill: red !important;

                    }
                }
            }

            .highcharts-yaxis-grid {
                position: relative;
                z-index: 100;

                path {
                    stroke: gray;
                    stroke-width: 1;
                    opacity: 1;
                    z-index: 100;
                    position: relative;
                    z-index: 100;
                }
            }

            .highcharts-axis-line {
                display: none;
            }

            .test-class {
                overflow: visible !important;
                transform: translate(75px, 16px);

                @include responsive(l) {
                    // transform: translate(-0px, 98px);

                    transform: translate(98px, 176px);
                }

                @include responsive(s) {
                    transform: translate(-23px, 195px) !important;

                }

                @include responsive(xs) {
                    transform: translate(0px, 21px) !important;
                }


                #highcharts-p1fhkg5-0 {
                    width: 760px !important;
                    height: 610px !important;
                    border: 1px solid red;
                }
            }

            #highcharts-5c1zhqn-0 {
                width: 760px !important;
            }

            .highcharts-container {
                width: 650px !important;
                height: 700px !important;

                @include responsive(l) {
                    width: 500px !important;
                    height: 500px !important;
                }

                @include responsive(s) {
                    width: 578px !important;
                    height: 587px !important;
                }

                @include responsive(xs) {
                    width: 86% !important;
                }
            }

            svg {
                width: 100%;

                .highcharts-root {
                    width: 100%;
                }

                .highcharts-graph {
                    stroke-dasharray: 2 !important;
                    stroke-width: 1 !important;
                    stroke-opacity: 0.6;
                }

                #highcharts-lmet6jd-0 {
                    width: 790px;
                }

                #highcharts-2xxar2r-0 {
                    width: 790px;
                }
            }
        }

        .container-graph__legenda-explicativa {
            width: fit-content;
            margin-left: 20%;
            font-weight: 500;
            margin-left: 28%;
            text-align: left;
            font-family: 'Roboto', sans-serif;
            margin: -5rem 0 2rem 28%;
            position: relative;
            left: -270px;
            top: -415px;

            @include responsive(l) {
                margin-top: -9rem;
            }


            @include responsive(s) {
                margin: -259px 0 1rem 0;
                top: auto;
                left: auto;
            }

            span {
                height: fit-content;
                font-weight: bold;
                font-size: 34px;
                color: gray;

                .ponto-contrast {
                    color: #000;
                    font-size: 42px;
                }
            }

            p {
                margin-top: -15px;
                font-weight: 300;
            }

        }

        .relatorio__box-btns {
            margin: 0 auto;
            width: fit-content;
            transform: translateX(calc(-6vw));

            @include responsive(s) {
                transform: translateX(0px);
            }

            @include responsive(xs) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .btn-relatorio {
                display: inline-block;
                background-color: #5B97DB;
                color: white;
                border-radius: 15px;
                padding: 14px 85px 14px 85px;
                cursor: pointer;
                margin: 0 30px;

                @include responsive(l) {
                    padding: 9px 65px 9px 65px;
                }

                @include responsive(xs) {
                    margin-top: 15px;
                }
            }
        }

        #chartdiv {
            width: 100%;
            height: 600px;
        }
    }
}

@media print {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body * {
        visibility: hidden;
        -webkit-print-color-adjust: exact;
    }

    #imprimir,
    #imprimir * {
        visibility: visible;
    }

    .no-print {
        display: none;
    }

    .logo-abrafati-print {
        display: block !important;
    }

    .container-relatorio {
        &.resultados {
            break-before: auto !important;
            margin-top: -10px;
        }
    }

    .container-graph {
        &.resultados {
            margin-top: -100px !important;
            margin-left: -20px !important;
        }
    }

    .container-graph__legenda-explicativa {
        &.resultados {
            left: -200px !important;
            bottom: -50px !important;
        }
    }

    .relatorio__container-results {
        &.resultados {
            margin-top: 20px !important;
            margin-bottom: 120px;
        }
    }

    .relatorio__container-results,
    .container-graph {
        &.conjunto {
            break-after: left;
            page-break-after: left;
        }
    }

    .box-relatorio-subtema {
        &.conjunto {
            break-before: left !important;
            page-break-before: left !important;
        }
    }

    .box-relatorio-congratulations {
        &.conjunto {
            break-before: left !important;
            page-break-before: left !important;
        }
    }

    .main-relatorio,
    .container-graph {
        &.conjunto {
            margin-top: 0px;
        }
    }

    .box-relatorio-subtema {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .title {
        margin-top: 25px !important;
    }

    .container-graph {
        transform: translateY(-50px);
    }


}

@page {
    margin-left: 0mm;
    margin-right: 3mm;
    margin-top: 8mm;
    margin-bottom: 6mm;
}