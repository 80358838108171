@import '../../css/style.scss';

.container-perguntas{
    margin-bottom: 120px;

    @include responsive(s){
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main-perguntas{
        width: 81%;
        font-size: 22px;
        margin: 18vh 0 0 14vw;
        padding-left: 55px;
        position: relative;
        z-index: 2;

        @include responsive(s){
            padding: 0;
            margin: 0;
            width: 92%;
        }
    }

    .btn-send-request{
        display: inline-block;
        border: none;
        border-radius: 14px;
        color: #fff;
        font-weight: 500;
        font-size: 17px;
        background-color: var(--color-tema);
        padding: 0.8rem 2.9rem 0.8rem 2.9rem;
        margin-top: 3rem;
    }
}
