.container-loading{
    position: fixed;
    top: calc(50vh - 40px);
    right: calc(50vw - 40px);
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .container{
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: calc(50vh - 40px);
      right: calc(50vw - 40px);
    }

    .message{
      position: relative;
      top: -40px;
      left: 50%;
      text-align: center;
      transform: translateX(-13%);
    }

    .circle{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #8DBFF6;
        margin-right: 5px;
        animation-name: animateLoading;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        
    }
    
    .circle-1 {
        animation-duration:0.75s ;
        animation-delay: 0;
    }
    .circle-2 {
        animation-duration: 0.75s ;
        animation-delay: 0.12s;
    }
    .circle-3 {
        animation-duration: 0.75s  ;
        animation-delay: 0.24s;
    }

    .loader{
        width: 100px;
        height: 80px;
    }

    @keyframes animateLoading {
        0% {
          transform: scale(1);
          transform: scale(1);
          opacity: 1;
        }
        16% {
          transform: scale(0.1);
          transform: scale(0.1);
          opacity: 0.7;
        }
        33% {
          transform: scale(1);
          transform: scale(1);
          opacity: 1; 
        } 
      }
}

 
