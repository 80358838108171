@import '../../css/style.scss';

label{
    display: flex;
    @include responsive(l){
        font-size: 16px;
    }

    .input-onClick {
        background-color: var(--color-tema);
        position: relative;
    }

    input{
        -webkit-appearance: none;
        appearance: none;
        background-color: $white-bg;
        margin-top: 0.2rem;
        color: $color-gov-2;
        font: inherit;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid var(--color-tema);
        border-radius: 50%;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        cursor: pointer;
        position: relative;

        &::before {
            content: "";
            width: 1.15em;
            height: 1.15em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--color-tema);
        }

        &:checked::before {
            transform: scale(1);
        }

        &:checked {
            outline: max(2px, 0.15px) solid var(--color-tema);
            outline-offset: max(2px, 0.15px);
        }  
    }

    .label {
        width: fit-content;
        margin-left: 10px;
        font-size: 16px;

        @include responsive(l){
            font-size: 14px;
        }
    }
}

.checks{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}