@import '../../css/style.scss';

.card-temas {
    position: relative;
    width: 90%;
    height: fit-content;
    border-radius: 20px 20px 20px 0;

    @include responsive(s) {
        width: 90%;
    }

    .card-temas__img{
        border-radius: 0px 0px 42px 23px;
        object-fit: cover;
        height: 14.813rem;
    }


    .card-temas__introduction{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .card-temas__text-introduction{
            width: 95%;
            color: #707070;
            font-size: 22px;
            margin-bottom: 35px;

            @include responsive(l){
                font-size: 16px;
            }
        }
    }
    

    .card-temas__description {
        background-color: $blue-card-tema;
        color: $white-bg;
        border-radius: 0 25px 25px;
        transform: translateY(-50px);
        padding: 25px 30px 30px;

        @include responsive(l) {
            padding: 25px 30px 30px;
        }

        @include responsive(s) {
            padding: 25px 10px 30px;
        }

        @include responsive(xs) {
            margin-top: 24px;
        }

        p {
            text-align: center;
            font-size: 28px;
           
            @include responsive(l) {
                font-size: 14px;
            }
        }
    }
}