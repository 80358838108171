@import '../../css/style.scss';

.checks-resultado {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;


    label {
        display: flex;

        @include responsive(l) {
            font-size: 16px;
        }

        .input-onClick1,
        .input-onClick5,
        .input-onClick9 {
            background-color: $color-gov;
            position: relative;

            &:checked {
                outline: max(2px, 0.15px) solid $color-gov;
            }
        }

        .input-onClick2,
        .input-onClick6,
        .input-onClick10 {
            background-color: $color-ch;
            position: relative;

            &:checked {
                outline: max(2px, 0.15px) solid $color-ch;
            }
        }

        .input-onClick3,
        .input-onClick7,
        .input-onClick11 {
            background-color: $color-cs;
            position: relative;

            &:checked {
                outline: max(2px, 0.15px) solid $color-cs;
            }
        }

        .input-onClick4,
        .input-onClick8,
        .input-onClick12 {
            background-color: $color-meioAm;
            position: relative;

            &:checked {
                outline: max(2px, 0.15px) solid $color-meioAm;
            }
        }

        >input {
            -webkit-appearance: none;
            appearance: none;
            background-color: $white-bg;
            margin-top: 0.2rem;
            color: $color-gov-2;
            font: inherit;
            width: 1.15em;
            height: 1.15em;
            border-radius: 50%;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
            cursor: pointer;
            position: relative;

            &::before {
                content: "";
                width: 1.15em;
                height: 1.15em;
                border-radius: 50%;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em transparent;
            }

            &:checked::before {
                transform: scale(1);
            }
        }

        .label {
            width: fit-content;
            margin-left: 10px;
            font-size: 16px;

            @include responsive(l) {
                font-size: 14px;
            }
        }
    }

    .input-tema1,
    .input-tema5,
    .input-tema9 {
        border: 0.15em solid $color-gov;
    }

    .input-tema2,
    .input-tema6,
    .input-tema10 {
        border: 0.15em solid $color-ch;
    }

    .input-tema3,
    .input-tema7,
    .input-tema11 {
        border: 0.15em solid $color-cs;
    }

    .input-tema4,
    .input-tema8,
    .input-tema12 {
        border: 0.15em solid $color-meioAm;
    }
}