@import '../../css/style.scss';

.politica-privacidade {
    display: flex;
    flex-direction: column;

    nav {
        width: 100%;
        height: 7rem;
        display: flex;

        .logo-menu {
            width: 5rem;
            height: fit-content;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .conteudo {
        width: 95%;
        margin-left: auto;
        padding: 2%;
        background-color: $white-bg;
        margin-top: 2rem;

        .container {
            width: 95%;
            margin-left: auto;
            margin-right: auto;

            h1 {
                font-size: 3rem;
                text-align: left;
                margin-bottom: 3rem;

                @include responsive(s){
                    font-size: 2rem;
                    text-align: center;
                }
            }

            .setor {
                margin-bottom: 4rem;
                display: flex;
                flex-direction: column;
                border-bottom: .1em solid rgba(0, 0, 0, 0.176);
                padding-bottom: 4rem;

                h3 {
                    font-size: 1.5rem;
                    margin-bottom: 1rem;
                    color: $blue-title;
                }

                p {
                    font-size: 1.2rem;
                    font-weight: 600;
                    text-align: left;
                    color: $gray-6;
                    line-height: 2rem;

                    a {
                        color: $purple-link;

                        &:hover {
                            color: $purple-link-hover;
                        }
                    }
                }
            }
        }
    }
}