@import '../../css/style.scss';

.container-arquivos{

    .container-arquivos-main{
        width: 75vw;
        height: fit-content;
        transform: translateX(14vw);
        padding-left: 5vw;
        margin-top: 166px;
        position: relative;
        z-index: 6;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 130px;
        flex-direction: column;


        @include responsive(s){
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 0px;
            transform: translateX(0px);
        }

        .box-previous-page{
            display: flex;
            width: fit-content;
            text-align: center;
            color: #349DEC;
            font-weight: 700;
            margin: 0px 0px 12px 0px;
            transform: translateX(-10px);
            position: relative;

            @include responsive(s){
                margin: 10px 0px 12px 5vw;
            }
            
            .icon-iteration{
                height: fit-content;
                transform: rotate(180deg) translateY(calc(50% - 27px));

                @include responsive(l){
                    font-size: 20px;
                    transform: rotate(180deg) translate(-10px ,calc(50% - 15px));
                }

                @include responsive(xs){
                    transform: rotate(180deg) translate(-14px, -15px);
                }

                svg{
                    height: 46px;
                    color:#679FDD;

                    @include responsive(l){
                        height: 30px;
                    }
                }
            }

            .title{
                font-size: 45px;
                margin-bottom: 20px;
                position: relative;

                @include responsive(l){
                    font-size: 25px;
                    margin-left: 10px;
                }
                
                @include responsive(s){
                    font-size: 26px;
                }

                @include responsive(xs){
                    font-size: 24px;
                    margin-top: 15px;
                }
            }

            &::after{
                content: '';
                position: absolute;
                width: 74%;
                height: 2px;
                right: 0px;
                bottom: 9px;
                background-color: transparent;
                border: 1px solid $gray-2;
                border-style: dashed;
            }
        }    
    }
}

.box-cards-archives{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    margin-top: 2rem;
    text-align: center;

    @include responsive(s){
        align-items: center;
        justify-content: center;
    }
}